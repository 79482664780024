<template>
    <div v-if="carouselData.length" :key="renderKey">
        <div class="canvas-parent">
            <div class="canvas-body" id="canvasParent">
                <custom-canvas
                    :ref="'sizeCanvas'"
                    :stockImage="carouselData[selectedIndex]"
                    :productData="productData"
                    :selectedDimension="
                        dimensions.filter((a) => a.selected == true)[0].name
                    "
                />
            </div>
            <div class="canvas-footer">
                <div class="reset" @click="handleDimensionClick(1)">RESET</div>
                <div class="scale-container">
                    <!-- Mapping dimensions from state array -->
                    <span
                        v-for="(item, index) in dimensions"
                        :key="'dimension-' + index"
                        :class="{ selected: item.selected }"
                        @click="handleDimensionClick(index)"
                        >{{ item.name }}</span
                    >
                </div>
            </div>
        </div>
        <div class="comparison-slider">
            <span class="comparison-slider-span">Comparison Options</span>
            <div class="carousel-parent">
                <stock-slider
                    :carouselData="carouselData"
                    :selectedIndex="selectedIndex"
                    :handleSlideClicked="handleSlideClicked"
                    :noOfSlides="noOfSlides"
                />
            </div>
        </div>
    </div>
</template>

<script>
import StockSlider from './common/StockSlider.vue'
import CustomCanvas from './common/CustomCanvas.vue'
export default {
    components: { StockSlider, CustomCanvas },
    name: 'modal-body',
    data() {
        return {
            dimensions: [
                {
                    name: 'in',
                    selected: false,
                },
                {
                    name: 'cm',
                    selected: true,
                },
            ],
            selectedIndex: 0,
        }
    },
    props: {
        carouselData: {
            type: Array,
            default: () => {
                return []
            },
        },
        productData: {
            type: Object,
        },
        renderKey: {
            type: String,
            default: 'canvas-modal',
        },
        noOfSlides: {
            type: Number,
        },
    },
    watch: {
        // watch over the carouselData
        carouselData() {
            // only if the data at selectedIndex is undefined
            if (!this.carouselData[this.selectedIndex]) {
                // decrease the selected index by 1 if the last pos was not the first element in the array
                if (this.selectedIndex != 0) this.selectedIndex -= 1
                // else increment by 1 position
                else this.selectedIndex += 1
            }
        },
    },
    methods: {
        /**
         *
         * @param {*} index Array index position
         * @author Rohan Shah
         * @description Changes the selected flag of the array item based on the index
         * and sets everything else as false.
         */
        handleDimensionClick(index) {
            this.$refs['sizeCanvas'].generateCanvas()
            let tempDimension = [...this.dimensions]
            tempDimension.forEach((item, indexPos) => {
                if (indexPos == index) item.selected = true
                else item.selected = false
            })
            this.dimensions = tempDimension
        },
        /**
         *
         * @param {*} index Array index position
         * @param {*} slide Slide object containing all data
         * @author Rohan Shah
         * @description Updates the canvas with new image and sets the state value for selectedIndex
         */
        handleSlideClicked(index) {
            this.selectedIndex = index
        },
    },
}
</script>

<style lang="less" scoped>
@import '../less/modal-body.less';
</style>
