<template>
    <VueSlickCarousel class="vue_slick_carousel" v-bind="settings">
        <div
            v-for="(slide, index) in carouselData"
            :key="'slide-' + index"
            class="slide-parent"
        >
            <div
                @click="handleSlideClicked(index, slide)"
                :class="{
                    'slide-container': true,
                    'selected-slide': selectedIndex == index,
                }"
            >
                <img :src="slide.url" :alt="slide.name" />
            </div>
            <span class="slide-name">{{ slide.name }}</span>
        </div>
    </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
export default {
    name: 'StockSlider',
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            settings: {
                color: 'black',
                infinite: false,
                speed: 500,
                slidesToShow: 5.3,
                slidesToScroll: 4,
                initialSlide: 0,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 3,
                        },
                    },
                     {
                        breakpoint: 1150,
                        settings: {
                            slidesToShow: 4.8,
                            slidesToScroll: 3,
                        },
                    },
                     {
                        breakpoint: 1120,
                        settings: {
                            slidesToShow: 4.4,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 1050,
                        settings: {
                            slidesToShow: 4.1,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3.9,
                            slidesToScroll: 2.5,
                        },
                    },
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 3.8,
                            slidesToScroll: 2.5,
                        },
                    },
                ],
            },
        }
    },
    props: {
        carouselData: {
            type: Array,
            default: () => {
                return []
            },
        },
        selectedIndex: {
            type: Number,
        },
        handleSlideClicked: {
            type: Function,
        },
        noOfSlides: {
            type: Number,
        },
    },
}
</script>

<style lang="less">
@import '../../less/stock-slider.less';
</style>
