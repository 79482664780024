<template>
    <div>
        <div @click="toggleModal" class="size-compare-label" v-if="showExt">
            Size Comparison
        </div>
        <div class="modal-overlay" id="app" v-if="showModal">
            <!-- If  -->
            <div class="modal-container">
                <loader v-if="showLoader" />
                <div class="modal-header">
                    <p>Size Comparison</p>
                    <img
                        src="../assets/pngs/close.png"
                        alt="close-btn"
                        @click="toggleModal"
                    />
                </div>
                <!-- If there is data in the carousel then load the canvas and other element -->
                <ModalBody
                    v-if="carouselData.length"
                    class="modal-body"
                    :carouselData="carouselData"
                    :productData="productData"
                />
                <!-- Else just show the error message along with appropriate image for display -->
                <div v-else class="no-data">{{ errorMessage }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from './common/Loader.vue'
import ModalBody from './ModalBody.vue'
// import mocks from '../mocks'
import axios from 'axios'
export default {
    components: { Loader, ModalBody },
    name: 'size-comparison-view',
    data() {
        return {
            carouselData: [],
            showLoader: true,
            showModal: false,
            errorMessage: 'No Product configuration found',
            productData: {},
            showExt: false,
        }
    },
    mounted() {
        // for each product change call the function to get data for carousel
        window.FPI.state.product.subscribe(this.getCarouselData.bind(this))
    },
    // unsubscribe after the component has been destroyed
    destroy() {
        window.FPI.state.product.unsubscribe(this.getCarouselData.bind(this))
    },
    methods: {
        /**
         * @author Rohan Shah
         * @description Function to call API to get carousel data and set in state
         */
        getCarouselData() {
            this.showExt = false
            let url = window.location.href.split('/')[2]
            let slug =
                window.FPI && window.FPI.state.product['_data'].product['slug']
            // Call the proxy URL to fetch data from extension eco-system server
            /* eslint-disable */
            // debugger;
            axios
                .get(
                    `https://${url}/ext/size-compare-alpha/application/api/v1.0/products/configuration/?slug=${slug}`
                )
                .then((response) => {
                    const { categoryConfig, productData } = response.data
                    // If product image is missing then dont show anything and throw an error
                    if (!productData.images.length) {
                        // new error message and hide loader
                        this.errorMessage =
                            'Product configuration is incomplete'
                        this.showLoader = false
                        return
                    }
                    // create props for productData
                    this.productData = {
                        height: parseInt(productData.length.value),
                        width: parseInt(productData.width.value),
                        url: productData.images[0],
                    }
                    // assign carousel data from the API response ( filtered data - only active assets )
                    this.carouselData = categoryConfig
                    if (this.carouselData.length) this.showExt = true
                })
                .catch((error) => {
                    this.showExt = false
                    const { data, status } = error.response
                    // only if the status code is 400 show the server error
                    if (status == 400) {
                        this.errorMessage = data.message
                    }
                    // else show generic error
                    else
                        this.errorMessage =
                            'Something went wrong. Please try again later'
                })
                // hide the loader
                .finally(() => {
                    this.showLoader = false
                })
        },
        toggleModal() {
            this.showModal = !this.showModal
        },
    },
}
</script>

<!-- Height 100% removed -->
<style lang="less" scoped>
@import '../less/size-comparison-view.less';
html,
body {
    margin: 0px !important;
    padding: 0px !important;
}

#app {
    font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto;
    height: inherit;
}
p {
    margin: 0;
}
</style>
