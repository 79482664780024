<template>
    <div id="container"></div>
</template>

<script>
// Import the canvas build js
import RaphaelCanvas from '../../external/raphael-canvas'
export default {
    name: 'CustomCanvas',
    data() {
        return {
            image: '',
            productImage: '',
            icon: '',
            canvasConfig: {},
            productImageConfig: {
                width: 0,
                height: 0,
            },
            iconConfig: {
                height: 16,
                width: 16,
            },
            stockImageConfig: {
                width: 0,
                height: 0,
            },
            stockAspectRatio: 0,
            productAspectRatio: 0,
            constPadding: 50,
            rotate: 'stockImage',
            productImageCoordinates: {
                x: 0,
                y: 0,
            },
            stockImageCoordinates: {
                x: 0,
                y: 0,
            },
            iconCoordinates: {
                x: 0,
                y: 0,
            },
            pImage: '',
            iImage: '',
            sImage: '',
            paper: '',
            rect: '',
            plinex: '',
            pliney: '',
            slinex: '',
            sliney: '',
            stockXScale: '',
            stockYScale: '',
            productXScale: '',
            productYScale: '',
        }
    },
    async mounted() {
        // once mounted call the function to generate the canvas on the ID - 'canvasParent'
        await this.generateCanvas()
    },
    watch: {
        // watch over the prop change
        stockImage() {
            // if it does generate the canvas again
            this.generateCanvas()
        },
    },
    methods: {
        /**
         * @author Rohan Shah
         * @description
         * Generate 2 configurations based on coordinates, image config and type (vertical/horizontal) scale
         *
         */
        getScaleConfigurations(coordinates, config, type, originalDimensions) {
            let rectConfig = {
                x: 0,
                y: 0,
                width: 100,
                height: 1,
                stroke: 'gray',
                strokeWidth: 1,
                opacity: 0.2,
                rotation: 0,
            }
            let textConf = {
                text: '',
                x: 0,
                y: 0,
                draggable: true,
                fill: 'black',
            }
            // if the function is invoked for vertical line config
            if (type == 'vertical') {
                //rect /stroke configuration
                rectConfig.x = coordinates.x - 10
                rectConfig.y = coordinates.y
                rectConfig.width = 1
                rectConfig.height = config.height
                //text configurations
                textConf.x = coordinates.x - 60
                textConf.y = coordinates.y + config.height / 2
                textConf.text = this.generateScale(
                    originalDimensions.length
                        ? originalDimensions.length
                        : originalDimensions.height,
                    this.selectedDimension
                )
            } else {
                //rect /stroke configuration
                rectConfig.x = coordinates.x
                rectConfig.y = coordinates.y + config.height + 10
                rectConfig.width = config.width
                // text configurations
                textConf.x = coordinates.x + config.width / 2 - 20
                textConf.y = coordinates.y + config.height + 20
                textConf.text = this.generateScale(
                    originalDimensions.width,
                    this.selectedDimension
                )
            }
            return { rectConfig, textConf }
        },
        /**
         * @author Rohan Shah
         * @description Returns the text value based on the selcted dimension
         * if inches then calculate else returns the value with cm / inch appended to the text
         */
        generateScale(value, type) {
            if (type == 'cm') {
                return `${value.toFixed(0)} ${type}`
            } else return `${(value.toFixed(0) / 2.54).toFixed(1)} ${type}`
        },
        /**
         * @author Rohan Shah
         * @description Increase the degree by 90
         */
        onRotateClick() {
            //* IMP to set the angle to 0 before firing 90 angle again
            /*
            The paper event holds previous and next items for the shape, so first need to set the prev value to 0
            then add 90 to the next value as it adds the angle instead of replacing it
            **/
            this[this.rotate == 'stockImage' ? 'sImage' : 'pImage'].rotate(0)
            this[this.rotate == 'stockImage' ? 'sImage' : 'pImage'].rotate(45)
            // clear the scale for better UX
            this.clearScales()
        },
        /**
         * @author Rohan Shah
         * @description Promisifies the image load
         * @param path | image path
         */
        loadImage(path) {
            return new Promise((resolve, reject) => {
                const img = new Image()
                //// img.crossOrigin = 'Anonymous' // to avoid CORS if used with Canvas
                img.src = path
                img.onload = () => {
                    resolve(img)
                }
                img.onerror = (e) => {
                    reject(e)
                }
            })
        },
        async setIcon() {
            return new Promise((resolve, reject) => {
                const icon = new window.Image()
                // Import from local assets
                icon.src = require('../../assets/pngs/rotate.png')
                icon.onload = () => {
                    this.icon = icon
                    resolve("ICON_ASSIGNED")
                }
                icon.onerror = () => {
                    reject("FAILED_TO_LOAD")
                }
            })
        },
        /**
         * @author Rohan Shah
         * @description Sets important values for the canvas like the height and width of the canvas.
         * Loads images for canvas based on the props being recieved and starts generating dimesnions for the images
         */
        async generateCanvas() {
            // * IMP to clear the canvas if it exists to prevent n number of SVGs being added to the dom
            //* This is being done as Rahpael Canvas package / js does not manage this properly
            if (this.paper != '') this.paper.remove()
            // get the canvas document
            let canvasParent = document.getElementById('canvasParent')
            // and set the state with the parent height and width
            this.canvasConfig = {
                width: canvasParent.offsetWidth,
                height: canvasParent.offsetHeight,
            }
            // invoke the canvas and set the returned paper in the state var
            this.paper = RaphaelCanvas(
                'container',
                canvasParent.offsetWidth,
                canvasParent.offsetHeight
            )
            let image = await this.loadImage(this.stockImage.url)
            // set image only when it is loaded
            this.image = image
            // Save the aspect ratio of the stock image based on its dimensions
            this.stockAspectRatio = image.height / image.width
            // image load for the product image being recieved as props
            const productImage = await this.loadImage(this.productData.url)
            this.productImage = productImage
            // image load for the icon from assets
            await this.setIcon();
            // save the aspect ratio of the product image
            this.productAspectRatio = productImage.height / productImage.width
            // passing the image data for both stock and product images configuration
            this.generateDimesions(
                this.stockImage,
                this.productData,
                this.paper
            )
        },
        /**
         * @author Rohan Shah
         * @param {*} image Stock Image configuration
         * @param {*} productImage Product Image configuration
         * @description Based on the origin size of the images, this function decides which image is to be shown as the main image
         * Along with this, it also performs calculations for image coordinates on the graph and the resize scale as per the
         * aspect ratio of the original image.
         *
         */
        generateDimesions(image, productImage, paper) {
            const { length: sh } = image
            const { height: ph } = productImage
            // get image with greatest height
            let mainImg = sh > ph ? 'stockImage' : 'productImage'
            let diffHeight = 0,
                relativeHeight = 0
            var xAxis,
                yAxis = 0
            switch (mainImg) {
                // case where the stock image is greater in length
                case 'stockImage':
                    if (image.length > 60) {
                        this.stockImageConfig = {
                            height:
                                this.canvasConfig.height - this.constPadding,
                            width:
                                (this.canvasConfig.height - this.constPadding) /
                                this.stockAspectRatio,
                        }
                    } else {
                        if (window.innerWidth <= 375) {
                            this.stockImageConfig = {
                                height: (this.canvasConfig.height * 30) / 100,
                                width:
                                    (this.canvasConfig.height * 30) /
                                    100 /
                                    this.stockAspectRatio,
                            }
                        } else {
                            this.stockImageConfig = {
                                height: (this.canvasConfig.height * 50) / 100,
                                width:
                                    (this.canvasConfig.height * 50) /
                                    100 /
                                    this.stockAspectRatio,
                            }
                        }
                    }
                    // compute the height for other image
                    diffHeight = sh - ph
                    // get the actual dimension
                    diffHeight = Math.abs(sh - diffHeight)
                    if (diffHeight > 100) diffHeight -= ph
                    relativeHeight =
                        (diffHeight *
                            (this.canvasConfig.height - this.constPadding)) /
                            100 -
                        this.constPadding

                    // **pixel convertion based on the productImage height
                    relativeHeight = (ph * this.stockImageConfig.height) / sh

                    this.productImageConfig = {
                        height: relativeHeight,
                        width: relativeHeight / this.productAspectRatio,
                    }
                    this.rotate = 'productImage'
                    this.stockImageCoordinates = {
                        x: 60,
                        y:
                            this.stockImageConfig.height >
                            this.canvasConfig.height / 2
                                ? 20
                                : this.canvasConfig.height / 2 -
                                  this.stockImageConfig.height / 2,
                    }
                    if (window.innerWidth <= 500) {
                        this.stockImageCoordinates.x = 30
                    }
                    this.productImageCoordinates = {
                        x:
                            this.canvasConfig.width -
                            this.productImageConfig.width -
                            this.iconConfig.width -
                            20,
                        y: this.canvasConfig.height / 2 - relativeHeight / 2,
                    }
                    // Sets the icon coordinates wrt the producy dimesnions as the product image is going to rotate
                    this.iconCoordinates = {
                        x:
                            this.productImageCoordinates.x +
                            this.productImageConfig.width,
                        y:
                            this.canvasConfig.height / 2 -
                            relativeHeight / 2 -
                            20,
                    }
                    break
                //case where the product image dimensions are greater than the stock image
                case 'productImage':
                    this.productImageConfig = {
                        height:
                            (this.canvasConfig.height * 50) / 100 -
                            this.constPadding,
                        width:
                            ((this.canvasConfig.height * 50) / 100 -
                                this.constPadding) /
                            this.productAspectRatio,
                    }
                    // only for mobile devices reduce the scaling further
                    if (window.innerWidth <= 500) {
                        this.productImageConfig = {
                            height:
                                (this.canvasConfig.height * 35) / 100 -
                                this.constPadding,
                            width:
                                ((this.canvasConfig.height * 35) / 100 -
                                    this.constPadding) /
                                this.productAspectRatio,
                        }
                    }
                    if (window.innerWidth > 500 && window.innerWidth <= 768) {
                        this.productImageConfig = {
                            height:
                                (this.canvasConfig.height * 35) / 100 -
                                this.constPadding,
                            width:
                                ((this.canvasConfig.height * 35) / 100 -
                                    this.constPadding) /
                                this.productAspectRatio,
                        }
                    }
                    if (window.innerWidth <= 375) {
                        this.productImageConfig = {
                            height:
                                (this.canvasConfig.height * 30) / 100 -
                                this.constPadding,
                            width:
                                ((this.canvasConfig.height * 30) / 100 -
                                    this.constPadding) /
                                this.productAspectRatio,
                        }
                    }
                    // compute the height for other image
                    diffHeight = ph - sh
                    //get the actual dimesnion in px for the image
                    diffHeight = Math.abs(ph - diffHeight)
                    if (diffHeight > 100) diffHeight -= sh
                    relativeHeight =
                        (diffHeight *
                            (this.canvasConfig.height - this.constPadding)) /
                        100

                    // pixel convertion based on the productImage height
                    relativeHeight = (sh * this.productImageConfig.height) / ph
                    // only if the relative height is very less then increase the total dimension by 20
                    if (relativeHeight < 30) {
                        relativeHeight += 20
                    }
                    this.stockImageConfig = {
                        height: relativeHeight,
                        width: relativeHeight / this.stockAspectRatio,
                    }
                    this.rotate = 'stockImage'
                    this.productImageCoordinates = {
                        x: 60,
                        y:
                            this.canvasConfig.height / 2 -
                            this.productImageConfig.height / 2,
                    }
                    this.stockImageCoordinates = {
                        // x: 120 + this.productImageConfig.width,
                        x:
                            (3 * this.canvasConfig.width) / 4 -
                            this.constPadding +
                            this.stockImageConfig.width / 2 -
                            this.constPadding,
                        y:
                            this.stockImageConfig.height >
                            this.canvasConfig.height / 2
                                ? this.canvasConfig.height / 4
                                : this.canvasConfig.height / 2 -
                                  this.stockImageConfig.height / 2,
                    }
                    if (window.innerWidth < 500) {
                        this.stockImageCoordinates.x += window.innerWidth / 10
                    }
                    // sets the icon coordinates wrt the stock image configurations
                    this.iconCoordinates = {
                        x:
                            this.stockImageCoordinates.x +
                            this.stockImageConfig.width,
                        y:
                            this.canvasConfig.height / 2 -
                            relativeHeight / 2 -
                            20,
                    }
                    break
                default:
                    break
            }
            // based on the condition decide the order of the images to be rendered
            if (this.rotate == 'productImage') {
                this.sImage = paper.image(
                    this.image.src,
                    this.stockImageCoordinates.x,
                    this.stockImageCoordinates.y,
                    this.stockImageConfig.width,
                    this.stockImageConfig.height
                )
                this.pImage = paper.image(
                    this.productImage.src,
                    this.productImageCoordinates.x,
                    this.productImageCoordinates.y,
                    this.productImageConfig.width,
                    this.productImageConfig.height
                )
            } else {
                this.pImage = paper.image(
                    this.productImage.src,
                    this.productImageCoordinates.x,
                    this.productImageCoordinates.y,
                    this.productImageConfig.width,
                    this.productImageConfig.height
                )
                this.sImage = paper.image(
                    this.image.src,
                    this.stockImageCoordinates.x,
                    this.stockImageCoordinates.y,
                    this.stockImageConfig.width,
                    this.stockImageConfig.height
                )
            }
            // change the rendere image based on dimension size info
            this[this.rotate == 'productImage' ? 'pImage' : 'sImage'].drag(
                this.move,
                this.start,
                this.up
            )

            this.iImage = paper.image(
                require('../../assets/pngs/rotate.png'),
                this.iconCoordinates.x,
                this.iconCoordinates.y,
                this.iconConfig.width,
                this.iconConfig.height
            )
            this.iImage.click(() => {
                this.onRotateClick()
            })
            xAxis = this.getScaleConfigurations(
                this.stockImageCoordinates,
                this.stockImageConfig,
                'horizontal',
                this.stockImage
            )
            yAxis = this.getScaleConfigurations(
                this.stockImageCoordinates,
                this.stockImageConfig,
                'vertical',
                this.stockImage
            )
            if (this.rotate == 'stockImage') {
                this.sliney = paper
                    .path([
                        'M',
                        yAxis.rectConfig.x,
                        this.stockImageCoordinates.y +
                            this.stockImageConfig.height,
                        'L',
                        yAxis.rectConfig.x,
                        this.stockImageCoordinates.y,
                    ])
                    .attr({ 'stroke-width': 0.5, stroke: 'gray' })
            } else {
                this.sliney = paper
                    .path([
                        'M',
                        yAxis.rectConfig.x,
                        this.stockImageCoordinates.y,
                        'L',
                        yAxis.rectConfig.x,
                        this.stockImageCoordinates.y +
                            this.stockImageConfig.height,
                    ])
                    .attr({ 'stroke-width': 0.5, stroke: 'gray' })
            }
            this.stockYScale = paper.text(
                this.stockImageCoordinates.x - 20,
                yAxis.textConf.y,
                yAxis.textConf.text
            )
            this.stockYScale.rotate(270)
            if (!['0 cm', '0.0 in'].includes(xAxis.textConf.text)) {
                this.slinex = paper
                    .path([
                        'M',
                        xAxis.rectConfig.x,
                        xAxis.rectConfig.y,
                        'L',
                        xAxis.rectConfig.x + this.stockImageConfig.width,
                        xAxis.rectConfig.y,
                    ])
                    .attr({ 'stroke-width': 0.5, stroke: 'gray' })
                this.stockXScale = paper.text(
                    xAxis.rectConfig.x + this.stockImageConfig.width / 2,
                    xAxis.rectConfig.y + 10,
                    xAxis.textConf.text
                )
            }
            // get config for product image data
            xAxis = this.getScaleConfigurations(
                this.productImageCoordinates,
                this.productImageConfig,
                'horizontal',
                this.productData
            )
            yAxis = this.getScaleConfigurations(
                this.productImageCoordinates,
                this.productImageConfig,
                'vertical',
                this.productData
            )
            // horizantal line
            this.plinex = paper
                .path([
                    'M',
                    xAxis.rectConfig.x,
                    xAxis.rectConfig.y,
                    'L',
                    xAxis.rectConfig.x + this.productImageConfig.width,
                    xAxis.rectConfig.y,
                ])
                .attr({ 'stroke-width': 0.5, stroke: 'gray' })
            // vertical line
            this.pliney = paper
                .path([
                    'M',
                    yAxis.rectConfig.x,
                    this.productImageCoordinates.y,
                    'L',
                    yAxis.rectConfig.x,
                    this.productImageCoordinates.y +
                        this.productImageConfig.height,
                ])
                .attr({ 'stroke-width': 0.5, stroke: 'gray' })
            this.productXScale = paper.text(
                xAxis.rectConfig.x + this.productImageConfig.width / 2,
                xAxis.rectConfig.y + 10,
                xAxis.textConf.text
            )
            this.productYScale = paper.text(
                this.productImageCoordinates.x - 20,
                yAxis.textConf.y,
                yAxis.textConf.text
            )
            this.productYScale.rotate(270)
        },
        /**
         * @author Rohan Shah
         * @description Hides the axis representations and the scale config being displayed to the user on canvas
         */
        clearScales() {
            this.pliney == '' ? () => {} : this.pliney.attr({ opacity: 0 })
            this.plinex == '' ? () => {} : this.plinex.attr({ opacity: 0 })
            this.sliney == '' ? () => {} : this.sliney.attr({ opacity: 0 })
            this.slinex == '' ? () => {} : this.slinex.attr({ opacity: 0 })
            this.productYScale == ''
                ? () => {}
                : this.productYScale.attr({ opacity: 0 })
            this.productXScale == ''
                ? () => {}
                : this.productXScale.attr({ opacity: 0 })
            this.stockYScale == ''
                ? () => {}
                : this.stockYScale.attr({ opacity: 0 })
            this.stockXScale == ''
                ? () => {}
                : this.stockXScale.attr({ opacity: 0 })
        },
        /**
         * @author Rohan Shah
         * @description Handles drag start
         */
        start() {
            this.odx = 0
            this.ody = 0
            this.clearScales()
            this.iImage.attr({ opacity: 0 })
        },
        /**
         * @author Rohan Shah
         * @description Handles acutal drag of the image
         */
        move(dx, dy) {
            let newX = dx - this.odx
            let newY = dy - this.ody
            // save the total bounding dimensions of the canvas
            let boundingBox =
                this[
                    this.rotate == 'productImage' ? 'pImage' : 'sImage'
                ].getBBox()
            // check if the positions are in negative OR more than the given canvas height / width
            // if so then bounce the image back to the position by appending a constant number
            if (boundingBox.x < 0) newX += 5
            if (boundingBox.x2 > this.canvasConfig.width) newX -= 10
            if (boundingBox.y < 0) newY += 5
            if (boundingBox.y2 > this.canvasConfig.height) newY -= 10
            // Transform the axis and then translate
            //* IMP DO NOT REMOVE ...T
            this[this.rotate == 'productImage' ? 'pImage' : 'sImage'].attr({
                transform: '...T' + newX + ',' + newY,
            })
            //  during drag make the non draggable image
            this[this.rotate == 'productImage' ? 'sImage' : 'pImage'].attr({
                opacity: 0.5,
            })
            this.iImage.translate(newX, newY)
            // this.iImage.translate(dx - this.odx, dy - this.ody)
            this.odx = dx
            this.ody = dy
        },
        /**
         * @author Rohan Shah
         * @description Handles drag end
         * remove opacity once the drag is over
         */
        up() {
            let boundingBox =
                this[
                    this.rotate == 'productImage' ? 'pImage' : 'sImage'
                ].getBBox()
            // check if the positions are in negative OR more than the given canvas height / width
            // if so then bounce the image back to the original positon
            if (
                boundingBox.x < 0 ||
                boundingBox.x2 > this.canvasConfig.width ||
                boundingBox.y < 0 ||
                boundingBox.y2 > this.canvasConfig.height
            ) {
                this.generateCanvas()
                return
            }
            this[this.rotate == 'productImage' ? 'sImage' : 'pImage'].attr({
                opacity: 1,
            })
            // calculate coordinates after drag for icon
            let attrs =
                this[this.rotate == 'productImage' ? 'pImage' : 'sImage'].next
                    .prev.attrs
            let x = attrs.x + attrs.width
            let y = attrs.y - this.iconConfig.height
            this.iImage.attr({ opacity: 1, x, y })
            this.odx = 0
            this.ody = 0
        },
    },
    props: {
        stockImage: {
            type: Object,
            required: true,
        },
        productData: {
            type: Object,
        },
        selectedDimension: {
            type: String,
        },
    },
}
</script>

<style></style>
