import SizeComparisonView from '@/components/SizeComparisonView'
// register the FPI object with new extension ID
window.FPI.extension.register('#ext-size-comparison-view', {
    mounted(element) {
        // mount the extension / Vue app on the given ID
        window.FPI.extension.mountApp({
            element,
            component: SizeComparisonView,
        })
    },
})
